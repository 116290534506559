<template>
  <ContainerBasic
    container-type="FULL_WIDTH"
    class="h-full touch:md:h-[320px] lg:h-[320px]"
  >
    <ClientOnly>
      <div
        v-if="isMobileDevice"
        :style="`background-color: ${setColorByGivenArea()}`"
        class="h-[140px] flex items-center bg-center bg-no-repeat bg-cover"
      >
        <img
          src="@/assets/images/newsletter-mobile.svg"
          class="relative z-0 object-cover w-full h-full"
          alt="Newsletter Banner"
        />
        <img
          v-if="isMobileDevice && blockData.imageData.mobile?.url"
          :src="blockData.imageData.mobile?.url"
          class="absolute h-[80px] pl-2xl z-10"
          alt="Newsletter Banner Icon"
        />
        <img
          v-else-if="isMobileDevice && newsletterImage"
          :src="imageSrc"
          class="absolute h-[80px] pl-2xl z-10"
          alt="Newsletter Banner Icon"
        />
        <img
          v-else
          :src="blockData.imageData.desktop?.url"
          class="absolute h-[80px] pl-2xl z-10"
          alt="Newsletter Banner Icon"
        />
      </div>
      <img
        v-if="!isMobileDevice"
        src="@/assets/images/newsletter.svg"
        class="h-[140] touch:md:h-[320px] lg:h-[320px] absolute z-[-10] min-w-full left-0 object-cover object-center"
        :style="`background-color: ${setColorByGivenArea()}`"
        alt="Newsletter Banner"
      />
      <ContainerBasic
        ref="bannerNode"
        container-type="PAGE"
        class="relative flex lg:h-full h-max"
      >
        <img
          v-if="isMobileDevice"
          src="@/assets/images/rechteck_transparent.svg"
          class="absolute z-0 object-cover min-w-full overflow-hidden"
          :style="`background-color: ${setColorByGivenArea()}; height: inherit`"
          alt="Newsletter Banner"
        />
        <div
          class="flex flex-col items-center h-full touch:md:justify-between touch:md:flex-row lg:justify-between lg:flex-row p-md"
        >
          <div
            class="relative z-20 flex flex-col touch:md:flex-row lg:flex-row"
          >
            <div class="touch:md:mb-sm lg:mb-sm">
              <img
                v-if="!isMobileDevice && newsletterImage"
                :src="imageSrc"
                class="touch:md:mr-2xl lg:mr-2xl h-[80px] pl-2xl touch:md:pl-0 touch:md:h-[130px] touch:md:mb-sm lg:pl-0 lg:h-[130px] lg:mb-sm"
                alt="Newsletter Banner Icon"
              />
              <img
                v-if="!isMobileDevice && blockData.imageData.desktop?.url"
                :src="blockData.imageData.desktop?.url"
                class="touch:md:mr-2xl lg:mr-2xl h-[80px] pl-2xl touch:md:pl-0 touch:md:h-[130px] touch:md:mb-sm lg:pl-0 lg:h-[130px] lg:mb-sm"
                alt="Newsletter Banner Icon"
              />
            </div>
            <div>
              <h2 class="text-2xl touch:md:text-3xl lg:text-3xl">
                {{ blockData.title }}
              </h2>
              <ul class="mt-xs">
                <li
                  v-for="(value, key) in blockData.newletterPoints"
                  :key="key"
                  class="flex align-middle mb-xs"
                >
                  <div
                    class="rounded-[16px] border-2 border-dotted w-[32px] h-[32px] mr-xs"
                    :style="`border-color: ${setColorByGivenArea()}`"
                  >
                    <div
                      class="w-[24px] h-[24px] rounded-xl m-[2px] text-white flex place-items-center justify-center"
                      :style="`background-color: ${setColorByGivenArea()}`"
                    >
                      <div v-if="iconClass === '1'" v-text="startIndex + key" />
                      <FaIcon classes="text-white" :icon-class="iconClass" />
                    </div>
                  </div>
                  <span class="mt-[5px]">{{ value }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-[330px] mt-sm touch:md:mt-0 lg:mt-0 z-20">
            <FormKit
              id="paywallForm"
              type="form"
              class="max-w-md"
              spellcheck="false"
              :actions="false"
              :incomplete-message="false"
              :config="{ validationVisibility: 'blur' }"
              @submit="
                onNewsletterSubmit(props.blockData.formAction, form, 'Block')
              "
              @node="watchForm"
            >
              <FormKit
                type="text"
                name="EMAIL"
                label="Ihre E-Mail-Adresse"
                placeholder="max@mustermann.de"
                validation="required|email"
                validation-visibility="blur"
                input-class="w-full input"
                inner-class="relative pt-[5px]"
                label-class="text-md !leading-[19px] font-bold"
                :validation-messages="{
                  email: 'E-Mail-Adresse ist ungültig.',
                }"
              />

              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <FormKit
                type="hidden"
                name="b_a8780ef3529534dbe1b892a2a_da0d21a119"
                value=""
              />

              <ButtonBasic
                classes="w-full my-sm px-0"
                label="Anmelden"
                bg-color="#555555"
                :type="EButtonType.SUBMIT"
                :btn-style="EButtonStyle.CTA"
                :show-dialog="showDialog"
                :dialog-content="dialogContent"
                :full-width="true"
                @close-dialog="onDialogWindowClosed"
              />

              <p class="text-[14px] leading-5">
                <Trans keypath="forms.termsDescription" tag="label">
                  <a
                    :href="t('forms.agb.slug')"
                    rel="noopener"
                    target="_blank"
                    class="font-bold text-vdv-base"
                  >
                    {{ t('forms.agb') }}
                  </a>
                  <a
                    :href="t('forms.privacy.slug')"
                    rel="noopener"
                    target="_blank"
                    class="font-bold text-vdv-base"
                  >
                    {{ t('forms.privacy') }}
                  </a>
                </Trans>
              </p>
            </FormKit>
          </div>
        </div>
      </ContainerBasic>
    </ClientOnly>
  </ContainerBasic>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { EButtonStyle, EButtonType } from '@/@types/basic-button';
import { reset } from '@formkit/core';
import { useAreaStore } from '@/stores/useArea';
import ContainerBasic from '@/components/components/container-basic.vue';
import ButtonBasic from '~~/src/components/components/core/basic-button/vdv/basic-button.vue';
import type { NewsletterBannerData } from '../blockType';
import FaIcon from '@/components/fa-icon.vue';
import Trans from '@/components/Trans.vue';
import { useImages } from '@/composables/useImages';
import { onNewsletterSubmit } from '~/composables/newsletterMailchimp';
import { FormKit } from '@formkit/vue';

const { t } = useTrans();
const bannerNode = ref(null);
const isDesktopView = useIsDesktopView();
const form = ref(null);
const areaStore = useAreaStore();
const startIndex = 1;

const showDialog = ref(false);
const dialogContent = ref({
  dialogHeadline: '',
  dialogText: '',
});
const dialogContentSuccess = {
  dialogHeadline: t('forms.dialogContent.successHeader'),
  dialogText: t('forms.dialogContent.successBody'),
};
const dialogContentError = {
  dialogHeadline: t('forms.dialogContent.errorHeader'),
  dialogText: '',
  dialogType: 'error',
};

const props = defineProps({
  blockData: {
    type: Object as PropType<NewsletterBannerData>,
    required: true,
    default: {} as NewsletterBannerData,
  },
  newsletterImage: {
    type: String,
    required: false,
    default: '',
  },
});

/**
 * @INFO
 * if no image in blockData -> newsletter is renderd for TYP
 * get newsletterImage only for academy and service via useImages().getImageSrc()
 */

const imageSrc = computed(() => {
  return useImages().getImageSrc(props.newsletterImage);
});

function setIconClass() {
  switch (props.blockData.enumerationBulletStyle) {
    case '0':
      return '';
    case '1':
      return '1';
    case '2':
      return 'fas fa-check';
  }
}

const iconClass = setIconClass();

function setColorByGivenArea() {
  if (props.blockData.area) {
    return props.blockData.area.color;
  } else {
    return areaStore.findAreaById(props.blockData.area.id).color;
  }
}

const isMobileDevice = computed(() => {
  return !isDesktopView.value;
});

function onDialogWindowClosed() {
  showDialog.value = false;
  reset('paywallForm');
}

if (import.meta.client) {
  (window as any).mcNewsletterBlock = {
    validateResponse: function (response: any) {
      dialogContent.value = {
        dialogHeadline: '',
        dialogText: '',
      };
      if (response.result === 'success') {
        dialogContent.value = dialogContentSuccess;
        if (response.msg.startsWith('Danke')) {
          //override the text when email already subscribed to newsletter
          dialogContent.value.dialogText = t(
            'forms.dialogContent.successThankYou',
          );
        }
        showDialog.value = true;
      } else {
        dialogContentError.dialogText =
          t('forms.dialogContent.errorBody') +
          '<br />' +
          '<span class="italic">' +
          response.msg +
          '</span>';
        dialogContent.value = dialogContentError;
        showDialog.value = true;
      }
    },
  };
}

function watchForm(n: any) {
  form.value = n;
}
</script>
